.vcc-modal {
  .ant-modal-header {
    padding: 16px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-steps {
    padding: 16px 0 0;
  }
  .ant-divider {
    margin: 0;
  }

  .ant-modal-header {
    border: none;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .grey-square {
      margin: 32px 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .info-card {
    border-radius: 2px;
    border: 1px solid var(--color-gray-100, #e5e5e5);
    background: #fff;
    opacity: 0.8;
    padding: 16px;
    margin: -16px 16px 32px;

    .info-list {
      margin-top: 4px;
      margin-bottom: 0;
      padding-inline-start: 32px;
    }
  }

  .width-100 {
    width: 100%;
  }
}
