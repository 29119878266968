.virtual-card-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;

  .card-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;

    .status {
      .anticon {
        color: #999999;
      }
    }
    .card-detail {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 16px;
    }

    .card-history {
      display: flex;
      padding-left: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-left: 1px solid #e5e5e5;

      .history-item {
        color: #999;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .card-description {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;

    .blue-variant {
      border: 1px solid #91d5ff;
      background: #e6f7ff;

      .anticon {
        color: #1890ff;
      }
    }

    .grey-variant {
      border: 1px solid #d9d9d9;
      background: #fafafa;

      .anticon {
        color: #bfbfbf;
      }
    }

    .bold {
      font-weight: 700;
    }
  }
}
