.ant-steps {
  padding: 12px 16px 0;

  .ant-steps-item {
    &::before {
      background-color: rgba(0, 0, 0, 0.85);
    }

    .ant-steps-item-container {
      .ant-steps-item-icon {
        color: rgba(0, 0, 0, 0.25);
        border-color: rgba(0, 0, 0, 0.25);
      }
    }

    &.ant-steps-item-process {
      .ant-steps-item-icon {
        background-color: rgba(0, 0, 0, 0.85);
        border-color: rgba(0, 0, 0, 0.85);
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-icon {
        > .ant-steps-icon {
          color: rgba(0, 0, 0, 0.85);
        }
        border-color: rgba(0, 0, 0, 0.85);
      }
    }

    .ant-steps-item-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .ant-steps-item.ant-steps-item-wait.ant-steps-item-disabled {
    .ant-steps-item-content.ant-steps-item-title {
      color: #b3b3b3;
    }
  }

  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process):not(
      .ant-steps-item-finish
    ) {
    .ant-steps-item-title {
      color: #b3b3b3;
    }
  }

  .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover {
    .ant-steps-item-icon {
      .ant-steps-icon {
        color: rgba(0, 0, 0, 0.85);
      }
      border-color: rgba(0, 0, 0, 0.85);
    }
    .ant-steps-item-title {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
