.booking-help {
  width: 100%;
  border: none;

  .ant-collapse-item:not(:last-child) {
    border-bottom: none;
  }

  .help-text {
    .help-list {
      padding-left: 20px;
    }
  }
}
