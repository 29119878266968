.extranet-payment {
  margin-left: 200px;

  &__content {
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
  }

  &__card {
    height: 100%;
    min-height: 460px;

    .ant-card-body {
      height: 100%;
      padding: 32px;
    }
    &.disabled {
      .ant-card-body .extranet-payment__card__content,
      .extranet-payment__description,
      .extranet-payment__title,
      .extranet-payment__icon {
        color: var(--color-gray-900, #1a1a1a);
        opacity: 0.4;
      }
    }
    &.active {
      border: 1px solid #ccc;
    }
    &__body {
      flex-direction: column;
      display: flex;
      height: 100%;
      gap: 24px;

      .vcc-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    &__top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: 100%;
      flex-grow: 1;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      &__icon {
        font-size: 24px;
        color: #999999;
      }
      &__top {
        width: 100%;
      }

      .new-tag {
        font-weight: 600;
        color: var(--color-primary-500, #ff2e63);
        margin-left: 8px;
        font-size: 16px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      font-size: 16px;
    }
    .info-list {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin: 0;
    }
  }

  &__button-info {
    margin-top: 8px;
    font-size: 12px;
    color: var(--color-gray-400, #999);
  }
}
.font-weight-600 {
  font-weight: 600;
}

.grey-square {
  border-radius: 2px;
  background-color: #f9f9f9;
  padding: 16px;

  .anticon {
    font-size: 20px;
  }
  .content-text-line {
    line-height: 18px;
  }
}
