.booking-detail-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  scroll-margin-top: 65px;

  .section-title {
    color: #999;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
  }

  .section-content {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 2px;
    border: 1px solid #f5f5f5;
  }
}
