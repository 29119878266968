#launcher {
  &.zendesk_bottom_65 {
    bottom: 65px !important;
  }

  &.zendesk_invisible {
    display: none !important;
  }

  bottom: 105px !important;
}
