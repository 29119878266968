.booking-detail {
  .ant-drawer-header-title {
    flex-direction: row-reverse;

    .ant-drawer-close {
      margin-right: 0;
    }
  }

  .booking-detail-title {
    .customer-info {
      color: #262626;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .booking-info {
      color: #1a1a1a;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .booking-detail-container {
    .booking-detail-menu {
      position: sticky;
      top: 0;
      z-index: 1;

      .ant-menu-item {
        padding: 8px 24px 0px;
      }
    }

    .booking-detail-content {
      display: flex;
      padding: 32px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
    }
  }
}
