.render-info {
  .render-info-title {
    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .render-info-content {
    color: #262626;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .render-info-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: #999;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
