.vcc-card {
  width: 300px;
  height: 190px;

  .vcc-card-container {
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #000;
    border-radius: 8px;

    &.isBlur {
      filter: blur(4px);
      background: #ccc;
      -webkit-user-select: none;
      user-select: none;
      pointer-events: none;
    }

    .vcc-card-logo {
      display: flex;
      width: 90px;
      flex-direction: column;
      align-items: flex-start;

      .staycation svg,
      .by-stripe {
        color: #fff;
        width: 100%;
      }

      .staycation {
        height: 16px;
      }

      .by-stripe {
        display: flex;
        align-items: center;
        gap: 2px;

        .processed-by {
          color: #fff;
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }

        .stripe-logo {
          display: flex;
          width: 28.686px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }
      }
    }

    .vcc-card-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .card-number {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
      .infos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card-secondary-infos {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 16px;

          .expiration-info,
          .cvc-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 80px;

            .title {
              color: #999;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
            .value {
              width: 100%;
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
            }
          }
        }

        .copy-button-container {
          display: flex;
          align-items: flex-end;

          .ant-btn {
            padding: 2px 8px;
            border: 1px solid #8c8c8c;
            color: #fff;
            font-size: 12px;
            display: flex;
            align-items: center;

            &:hover {
              background: #f6f6f6;
              color: #2d2d2d;
            }
          }

          #card-number-copy {
            width: 100%;
            position: absolute;
          }
        }
      }
    }
  }
}
