@import 'src/styles/commons';

.experience-title {
  .container {
    width: 100%;
  }

  .input-row {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0px;
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;

      .ant-select {
        width: 320px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input {
        width: 440px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input-number {
        width: 104px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-form-item-label {
        margin-right: 16px;
        width: initial;
        max-width: unset;

        label {
          font-weight: 500;
          font-size: 14px;
        }

        label.ant-form-item-required::before {
          display: none;
        }

        label::after {
          display: none;
        }
      }

      .view-text {
        line-height: 32px;
      }

      .view-text-area {
        white-space: pre-wrap;
      }
    }
  }

  .banner {
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 16px;
    display: flex;
    gap: 10px;
    align-items: baseline;

    .textContainer {
      display: flex;
      flex-direction: column;
    }

    .icon {
      color: #8c8c8c;
      font-size: 16px;
    }

    .title {
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }

    .banner-content {
      color: #808080;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 32px
    }

    .banner-list {
      padding-inline-start: 20px;

      .banner-list {
        list-style-type: disc;
      }
    }

    .emphasized {
      font-style: normal;
      font-weight: 500;
    }

    .strongly-emphasized {
      font-style: normal;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
