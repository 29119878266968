.auto-inventory-modal {
  .ant-modal-header {
    border: none;
  }
  .ant-divider {
    margin: 0;
  }

  .container-modal {
    height: 100%;
    position: relative;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;

      .explanation {
        padding: 32px 16px 0;
      }
    }

    .room-mapping-step,
    .bar-step,
    .discount-step,
    .preview-step {
      .explanation {
        color: #1a1a1a;
      }
    }
    .room-mapping-alert.ant-alert,
    .preview-alert.ant-alert {
      border: 1px solid #e5e5e5;
      background: #f9f9f9;
      width: 100%;

      .anticon {
        color: #808080;
      }
    }
  }
}

.auto-inventory-tooltip-stock {
  .ant-tooltip-inner {
    padding: 0;

    .tooltip-container {
      width: 300px;
      padding: 12px;
      background-color: #fff;
      color: #101010;
      font-size: 12px;
      line-height: 18px;

      .tooltip-title {
        color: #101010;
      }

      .tooltip-subtitle {
        margin-top: 8px;
        color: #9c9c9c;
      }
    }
  }
}

.scrapping-mask {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
