@import './fonts/fonts';

@import './antd/badge';
@import './antd/button';
@import './antd/collapse';
@import './antd/descriptions';
@import './antd/form';
@import './antd/input';
@import './antd/menu';
@import './antd/message';
@import './antd/select';
@import './antd/table';
@import './antd/picker';
@import './antd/steps';
@import './commons/zendesk';

$font-family: system-ui, 'Helvetica Neue', sans-serif;

body {
  font-family: $font-family;
}

#root {
  // sass-lint:disable-line no-ids
  height: 100%;
}

.app {
  height: 100%;
}
