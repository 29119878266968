.payment-detail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  .payment-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    .payment-detail-title {
      color: #1a1a1a;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }

    .payment-detail-info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      .content-value {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 32px;
        align-self: stretch;

        .payment-detail-content,
        .payment-detail-value {
          color: #262626;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        .payment-detail-content {
          flex: 1 0 0;
          &.asTitle {
            color: #262626;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
        }
      }
    }

    .payment-detail-description {
      color: #999;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .payment-detail-divider {
      margin: 12px 0 0;
    }
  }

  .blue-variant {
    width: 100%;
    border: 1px solid #91d5ff;
    background: #e6f7ff;

    .anticon {
      color: #1890ff;
    }
  }
}
