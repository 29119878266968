.ant-tree-switcher {
  width: 0;
}

.divider-element {
  width: 100%;

  .ant-tree-node-content-wrapper {
    width: 100%;
    padding: 0;
    min-height: 0;

    .ant-divider {
      margin: 8px 0;
    }
  }
}
